//React
import React, { Suspense, lazy } from "react";

//React Router
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

// Ant Design y Moment para la localización
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";
import "moment/locale/es";

// Configura moment en español
moment.locale("es");

// App (Lazy Loaded)
const App = lazy(() => import("./App"));

// Css
import "./configs/theme.less";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
//import "./assets/styles/fix.css";

// Custom Loading Component
import LoadingComponent from "./loading/LoadingComponent";

ReactDOM.render(
  <Suspense fallback={<LoadingComponent />}>
    <BrowserRouter>
      <ConfigProvider locale={esES}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
