import React from "react";

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p>Cargando...</p>
        <div
          className="spinner"
          style={{
            margin: "auto",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "5px solid #f3f3f3",
            borderTop: "5px solid #3498db",
            animation: "spin 2s linear infinite",
          }}
        ></div>
      </div>
    </div>
  );
};

// Estilo para la animación del spinner
const style = document.createElement("style");
style.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);

export default LoadingComponent;
